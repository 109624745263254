export  const navItems = [
   
    {
      category: "Profile",
      iClass:'bi bi-graph-up',
      url:"/services/affiliates/banking"
    },
    {
      category: "Settings",
      iClass:'bi bi-briefcase-fill',
      url:"/services/affiliates"

    },
    {
      category: "Chats",
      iClass:'bi bi-cash-stack',
      url:"/services/affiliates"

    },

  ];