import useAxios from "../../utility/axios-token-manager/init";
import { loadStart, loadStop } from "./loading";

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(loadStart());
    const response = await useAxios.get(
      "/influencer-marketing/influencer-dashboard"
    );
    dispatch(loadStop());
    const { code } = response.data;
    console.log(code, response.data);
    if (code === 200) {
      return dispatch({
        type: "SET_PROFILE",
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch(loadStop());
  }
};
