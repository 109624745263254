import * as React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Typography, Paper } from "@material-ui/core";
import { connect } from "react-redux";


function OngoingTasks({ongoing}) {

  return (
    <Paper>
      <Typography className="pt-3 text-center">Ongoing Task</Typography>
      {!ongoing.length && (
        <div className='p-4'>
          No record at this time...
        </div>
      )}
      {ongoing?.length > 0 && (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {ongoing.map((item, index) => (
            <ListItem alignItems="flex-start" key={index}>
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={item.MerchantId?.fullname}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="p"
                      variant="body2"
                      color="text.primary"
                    >
                      {item.productServiceCategory}
                    </Typography>
                    {`  ${item.MerchantId?.fullname}  has requested for your service.`}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
          <Divider variant="inset" component="li" />
        </List>
      )}
    </Paper>
  );
}

const mapStateToProps = (state) => {
  const jobs = state.profile?.merchantsData;
  const ongoing = jobs.filter(
    (item) => item.negotiationStatus === "ongoing"
  );
  // console.log(ongoing)
  return {
    ongoing: ongoing,
  };
};

export default connect(mapStateToProps)(OngoingTasks);
