import React from 'react'

export default function Index() {
    // console.log(process.env.REACT_APP_TEST_VAR)
    return (
        <div>
            Landing Page
        </div>
    )
}
