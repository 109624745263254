import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import styles from "./styles.module.css";

function Index({ongoing,completed,pending}) {

  const item = [
    {
      title: "Completed",
      count: completed?.length,
    },
    {
      title: "Task at Hand",
      count: ongoing?.length,
    },
    {
      title: "Pending Jobs",
      count: pending?.length,
    },
  ];
  return (
    <>
      <Paper className={styles["paper"]}>
        <div className="row">
            {item.map((value,index)=><div className={styles["card"] + " col-lg-4"}>
                <Typography>{value.title}</Typography>
                <Typography variant='h3'>{value.count}</Typography>
            </div> )}
        </div>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => {
  const jobs = state.profile?.merchantsData;
  const completed = jobs.filter(
    (item) => item.negotiationStatus === "completed"
  );
  const ongoing = jobs.filter(
    (item) => item.negotiationStatus === "ongoing"
  );
  const pending = jobs.filter(
    (item) => item.negotiationStatus === "pending"
  );
  // console.log(completed)
  return {
    completed,pending,ongoing
  };
};

export default connect(mapStateToProps)(Index)
