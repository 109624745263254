import * as React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Typography, Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import useAxios from "../../../../../utility/axios-token-manager/init";

function NewRequest({ data }) {
  // console.log(data, 'here')

  const handleDelete =async (id) => {
    try {
      alert(`delete ${id}`)
      const response =await useAxios.delete(
        `/influencer-marketing/merchant/influencer-decline/${id}`
      );
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  };
  const handleAccept =async (id, amount) => {
    try {
      alert(`delete ${id}`)
      const response =await useAxios.put(
        `/influencer-marketing/influencer-accept/${id}`, {finalPrice:amount}
      );
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <Paper>
      <Typography className="pt-3 text-center">New Request</Typography>
      {!data.length && <div className="p-4">No record at this time...</div>}
      {data?.length > 0 && (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {data.map((item, index) => (
            <ListItem alignItems="flex-start" key={index}>
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={item.MerchantId?.fullname}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="p"
                      variant="body2"
                      color="text.primary"
                    >
                      {item.productServiceCategory}
                    </Typography>
                    {`  ${item.MerchantId?.fullname}  has requested for your service. ${item.offerPrice}`}
                    <div>
                      <Button onClick={()=>handleAccept(item._id, item.offerPrice)}>Accept</Button>
                      <Button>Negotiate</Button>
                      <Button  onClick={()=>handleDelete(item._id)}>Decline</Button>
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
          <Divider variant="inset" component="li" />
        </List>
      )}
    </Paper>
  );
}

const mapStateToProps = (state) => {
  const jobs = state.profile?.merchantsData;
  const newRequest = jobs.filter(
    (item) => item.negotiationStatus === "pending"
  );
  // console.log(newRequest)
  return {
    data: newRequest,
  };
};

export default connect(mapStateToProps)(NewRequest);
