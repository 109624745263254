import React from "react";
import Statistic from "./subs/statistics/Index";
import NewRequest from "./subs/request/Index";
import Task from "./subs/ongoingTask";
import CompletedTasks from "./subs/completedTask";
import { Grid } from "@material-ui/core";

//
export default function Index() {
  return (
    <div>
      <Statistic />
      <Grid container spacing={2} className="mt-4">
        <Grid item xs={12} md={6}>
          <NewRequest />
        </Grid>
        <Grid item xs={12} md={6}>
          <Task />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompletedTasks />
        </Grid>
      </Grid>
    </div>
  );
}


